import {onGetSingleIEP} from "../../http/iepAPis";
import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {
    Box, Table, Thead, Tbody, Tr, Th, Td, Text, Input, Textarea, VStack, HStack, Divider, Flex, Button, Image, Avatar
} from "@chakra-ui/react";
import {useReactToPrint} from "react-to-print";
import {onGetTherapist, onGetUsers} from "../../http/users";
import {Roles, User} from "../../interfaes/user";

export default function IEPReport() {
    const [iepFormAPI, setIEPFormAPI] = useState();
    const [loading, setLoading] = useState(true);

    const {studentId, iepID} = useParams();

    const componentRef = useRef();

    const [therapist, setTherapist] = useState();
    const [paarent, setParent] = useState();

    const handleAfterPrint = React.useCallback(() => {
        console.log("`onAfterPrint` called");
    }, []);

    const getTherapist = async (id, pid) => {
        try {
            const response = await onGetUsers();
            const t = response.data.userResponseList.filter((e) => {
                return e?.roles?.includes(Roles.THERAPIST) && e?.id === id;
            })
            const p = response.data.userResponseList.filter((e) => {
                return e?.roles?.includes(Roles.PARENT) && e?.id === pid;
            })

            setTherapist(t[0]);
            setParent(p[0]);

        } catch (e) {
            console.log(e);
        }
    }

    const handleBeforePrint = React.useCallback(() => {
        console.log("`onBeforePrint` called");
        return Promise.resolve();
    }, []);

    const printFn = useReactToPrint({
        contentRef: componentRef,
        documentTitle: "IEP_REPORT_" + studentId + '_' + iepID,
        onAfterPrint: handleAfterPrint,
        onBeforePrint: handleBeforePrint,
    });

    const [goalAndObj, setGoalAndObj] = useState([]);


    useEffect(() => {
        getIEP();
    }, []);

    const getIEP = async () => {
        if (studentId && iepID) {
            try {
                const response = await onGetSingleIEP(studentId, iepID);
                getTherapist(response.data?.student?.therapistId,response.data?.student?.parentId);
                setIEPFormAPI(response.data);

                setLoading(false);
                const obj = [];
                response.data?.iepSteps?.goalsAndObjectives?.firstQuarterGoals.forEach((i) => {
                    const goal = {}
                    goal.goal = i?.goal;
                    goal.area = i?.area;
                    goal.subArea = i?.subArea;
                    const firstObj = [...i?.objectives];
                    goal.firstObj = firstObj;

                    obj.push(goal)
                    setGoalAndObj(obj)


                })

                response.data?.iepSteps?.goalsAndObjectives?.secondQuarterGoals.forEach((si, index) => {
                    const secondObj = [...si?.objectives];
                    obj[index].secondObj = secondObj;
                })
                response.data?.iepSteps?.goalsAndObjectives?.thirdQuarterGoals.forEach((ti, index) => {
                    const thirdObj = [...ti?.objectives];
                    obj[index].thirdObj = thirdObj;
                })


                console.log(obj);
            } catch (e) {
                console.log(e);
            }
        }
    }
    if (loading) {
        return <Box>Loading...</Box>
    }
    return (<Box>
        <Button colorScheme="blue" mb={4} onClick={printFn}>Download PDF</Button>

        <Box p={6} ref={componentRef}>

            {/* Student Details */}
            <Text fontSize="xl" fontWeight="bold">Student Details</Text>
            <Box background={'blue.50'} mt={'14px'} p={'10px'} borderRadius={10} w={'100%'}>
                <Flex>
                    <Flex w={'10%'} justifyContent={'center'}>
                        <Avatar
                            borderRadius='full'
                            height={'120px'}
                            width={'120px'}
                            src={iepFormAPI?.student?.studentImage}
                            objectFit={'fill'}
                            alt='Dan Abramov'
                            fallbackSrc='https://via.placeholder.com/150'
                        />
                    </Flex>
                    <Box w={'45%'} ml={'26px'}>


                        <VStack align="start" spacing={2} mt={2}>
                            <Flex w="full"><Text w={'20%'}
                                                 fontWeight={900}>ID:</Text><Text>{iepFormAPI?.student?.studentId} </Text></Flex>
                            <Flex w="full"><Text w={'20%'}
                                                 fontWeight={900}>Name:</Text><Text>{iepFormAPI?.student?.fullName} </Text></Flex>
                            <Flex w="full"><Text w={'20%'}
                                                 fontWeight={900}>Address:</Text><Text>{iepFormAPI?.student?.localLevel}, {iepFormAPI?.student?.district}, {iepFormAPI?.student?.province} </Text></Flex>
                            <Flex w="full"><Text w={'20%'} fontWeight={900}>Date of
                                Birth:</Text><Text>{new Date(iepFormAPI?.student?.dob).toLocaleDateString()} </Text></Flex>
                            <Flex w="full"><Text w={'20%'} fontWeight={900}>IEP
                                Year:</Text><Text>{iepFormAPI?.year} </Text></Flex>
                            <Flex w="full"><Text w={'20%'}
                                                 fontWeight={900}>Disability
                                Type:</Text><Text>{iepFormAPI?.student?.disabilityType} </Text></Flex>
                            <Flex w="full"><Text w={'20%'}
                                                 fontWeight={900}>Disability
                                Id:</Text><Text>{iepFormAPI?.student?.disabilityId} </Text></Flex>
                            <Flex w="full"><Text w={'20%'}
                                                 fontWeight={900}>Special
                                Disability:</Text><Text>{iepFormAPI?.student?.specialDisability}, {iepFormAPI?.student?.district}, {iepFormAPI?.student?.province} </Text></Flex>
                            <Flex w="full"><Text w={'20%'} fontWeight={900}>
                                Disability Detail:</Text><Text>{iepFormAPI?.disabilityDetail} </Text></Flex>

                        </VStack>
                    </Box>
                    <Box w={'45%'}>
                        <Flex w="full"><Text w={'20%'}
                                             fontWeight={900}>IEMISNumber: </Text><Text>{iepFormAPI?.student?.IEMISNumber} </Text></Flex>
                        <Flex w="full"><Text w={'20%'}
                                             fontWeight={900}>Language: </Text><Text>{iepFormAPI?.student?.language} </Text></Flex>
                        <Flex w="full"><Text w={'20%'} fontWeight={900}>Service
                            Area: </Text><Text>{iepFormAPI?.student?.serviceArea} </Text></Flex>
                        <Flex w="full"><Text w={'20%'}
                                             fontWeight={900}>Category: </Text><Text>{iepFormAPI?.student?.category} </Text></Flex>
                        <Flex w="full"><Text w={'20%'}
                                             fontWeight={900}>Contact: </Text><Text>{iepFormAPI?.student?.contact} </Text></Flex>
                        <Flex w="full"><Text w={'20%'}
                                             fontWeight={900}>School: </Text><Text>{iepFormAPI?.student?.school?.name} </Text></Flex>
                        <Flex w="full"><Text w={'20%'} fontWeight={900}>Therapist
                            : </Text><Text>{therapist?.displayName} </Text></Flex>
                        <Flex w="full"><Text w={'20%'} fontWeight={900}>Parent
                            : </Text><Text>{paarent?.displayName} </Text></Flex>
                    </Box>

                </Flex>
            </Box>


            {/* Strengths and Limitations */}
            <Text fontSize="xl" fontWeight="bold" mt={14}>Strengths and Limitation</Text>

            <Box background={'blue.50'} mt={'14px'} p={'10px'} borderRadius={10} my={6}>
                <VStack align="start" spacing={2} mt={2}>
                    {iepFormAPI?.iepSteps?.strengthAndLimitations.map((item) => {
                        return (<Box key={item.id}>
                            <Text fontWeight={900}>{item.questionNP}</Text>
                            <Text>
                                {item?.answer?.content}
                            </Text>
                        </Box>);
                    })}

                </VStack>
            </Box>


            {/* Initial and Final Checklist */}
            <Box my={6}>
                <Text fontSize="xl" fontWeight="bold">Initial and Final Checklist</Text>
                <Box background={'blue.50'} mt={'14px'} p={'10px'} borderRadius={10}> <Table variant="simple" mt={2}>
                    <Thead>
                        <Tr background={'#1b73e8'}>
                            <Th fontWeight={900} color={'#fff'} fontSize={'16px'}>Activities</Th>
                            <Th fontWeight={900} color={'#fff'} fontSize={'16px'}>Initial</Th>
                            <Th fontWeight={900} color={'#fff'} fontSize={'16px'}>Final</Th>
                        </Tr>
                    </Thead>
                    {iepFormAPI?.iepSteps?.checkListAreas.map((item, i) => {
                        return item.checklist.map((c, j) => (<Tr key={c.id}>
                            <Td style={{border: '1px solid #1b73e8', padding: 6}}>{c.questionNP}</Td>
                            <Td style={{border: '1px solid #1b73e8', padding: 6}}>{c?.answer?.content || '-'}</Td>
                            <Td style={{
                                border: '1px solid #1b73e8', padding: 6
                            }}>{iepFormAPI?.iepSteps?.finalChecklist[i]?.checklist[j]?.answer?.content}</Td>
                        </Tr>));
                    })}
                </Table>
                </Box>
            </Box>


            {/* Goals and Objectives Table */}
            <Box my={6}>
                <Text fontSize="xl" fontWeight="bold">Goals and Objectives</Text>
                {goalAndObj?.map((item) => {
                    return (<Box background={'blue.50'} mt={'14px'} p={'10px'} borderRadius={10}>
                        <Text fontSize={'16px'} fontWeight={900}>{item?.goal?.answer?.content}</Text>
                        <Text fontSize={'16px'} fontWeight={900}>{item?.area?.answer?.content}</Text>
                        <Text fontSize={'16px'} fontWeight={900}>{item?.subArea?.answer?.content}</Text>
                        <Table variant="simple" mt={2}>
                            <Thead>
                                <Tr background={'#1b73e8'}>
                                    <Th fontWeight={900} color={'#fff'} fontSize={'16px'}>Objectives</Th>
                                    <Th fontWeight={900} color={'#fff'} fontSize={'16px'}>Strategy</Th>
                                    <Th fontWeight={900} color={'#fff'} fontSize={'16px'}>Material</Th>
                                    <Th fontWeight={900} color={'#fff'} fontSize={'16px'}> Responsible</Th>
                                    <Th fontWeight={900} color={'#fff'} fontSize={'16px'}>Communication</Th>
                                    <Th fontWeight={900} color={'#fff'} fontSize={'16px'}>Review</Th>
                                    <Th fontWeight={900} color={'#fff'} fontSize={'16px'}>Comment</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                <Tr fontWeight={900} color={'#fff'} fontSize={'16px'} background={'#1b73e8'}
                                    textAlign={'center'}>
                                    <td colSpan={7}>FIRST QUATER</td>
                                </Tr>
                                {item.firstObj.map((fi, index) => {
                                    return (<Tr>

                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.objectiveAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.activityAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.materialAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.responsibleAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.communicationStrategyAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{iepFormAPI?.iepSteps?.firstReview?.reviews[index]?.answer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{iepFormAPI?.iepSteps?.firstReview?.reviews[index]?.answer?.remarks}</td>
                                    </Tr>);
                                })}
                                <Tr fontWeight={900} color={'#fff'} fontSize={'16px'} background={'#1b73e8'}
                                    textAlign={'center'}>
                                    <td colSpan={7}>SECOND QUATER</td>
                                </Tr>
                                {item.secondObj.map((fi, index) => {
                                    return (<Tr>

                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.objectiveAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.activityAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.materialAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.responsibleAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.communicationStrategyAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{iepFormAPI?.iepSteps?.secondReview?.reviews[index]?.answer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{iepFormAPI?.iepSteps?.secondReview?.reviews[index]?.answer?.remarks}</td>
                                    </Tr>);
                                })}
                                <Tr fontWeight={900} color={'#fff'} fontSize={'16px'} background={'#1b73e8'}
                                    textAlign={'center'}>
                                    <td colSpan={7}>THIRD QUATER</td>
                                </Tr>
                                {item.thirdObj.map((fi, index) => {
                                    return (<Tr>

                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.objectiveAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.activityAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.materialAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.responsibleAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{fi?.communicationStrategyAnswer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{iepFormAPI?.iepSteps?.finalReview?.reviews[index]?.answer?.content}</td>
                                        <td style={{
                                            border: '1px solid #1b73e8', padding: 6
                                        }}>{iepFormAPI?.iepSteps?.finalReview?.reviews[index]?.answer?.remarks}</td>
                                    </Tr>);
                                })}

                            </Tbody>

                        </Table></Box>);
                })}
            </Box>
        </Box>
    </Box>)
}

import axios from "axios";
import {useEffect, useState} from "react";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export function StudentScore() {
    const [inputData, setInputData] = useState({});

    useEffect(() => {
        onGetData();
    }, []);
    const onGetData = () => {
        axios.post('/dashboard/reviewScore', {
        }).then((res) => {
             let transformedData = transformData(res?.data).sort((a, b) => a.name - b.name);
             console.log(transformedData);
             setInputData(transformedData);
        });
    }
    const transformData = (data) => {
        // Get all possible score ranges
        const allRanges = new Set();
        Object.values(data.scores).forEach(yearData => {
            Object.keys(yearData).forEach(range => {
                allRanges.add(range);
            });
        });

        // Convert to array format
        const result = Object.entries(data.scores).map(([year, scores]) => {
            // Create base object with year as name
            const obj = { name: year };

            // Add all ranges with their values (0 if not present)
            allRanges.forEach(range => {
                obj[range.replace(/\s+/g, '_')] = scores[range] || 0;
            });

            return obj;
        });

        return result;
    };


  return (
      <div>
          <div>
              <ResponsiveContainer width={'100%'} height={400}>
                  <BarChart
                      data={inputData}
                      margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 5,
                      }}
                  >
                      <CartesianGrid strokeDasharray="3 3"/>
                      <XAxis dataKey="name"/>
                      <YAxis/>
                      <Tooltip/>
                      <Legend/>
                      <Bar dataKey="less_than_2" fill={'#0088FE'} name={"Very Low Scoring"}/>
                      <Bar dataKey="2_To_2.9" fill="#00C49F" name={"Low Scoring"}/>
                      <Bar dataKey="3_To_3.9" fill="#FFBB28" name={"Mid Scoring"}/>
                      <Bar dataKey="4" fill="#FF8042" name={"Full Scoring"}/>
                  </BarChart>
              </ResponsiveContainer>
          </div>
      </div>
  );
}
import {
    Badge,
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    IconButton,
    Image, Skeleton,
    Stack, StackDivider,
    Text,
    useDisclosure, useToast
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {Roles} from "../../interfaes/user";
import {RelationShip, Student} from "../../interfaes/student";
import {MdAdd, MdCloudDownload, MdFileDownload} from "react-icons/all";
import ManageIEP from "./ManageIEP";
import {onDeleteIEP, onGetIEP} from "../../http/iepAPis";
import {IEP} from "../../interfaes/iep";
import {GrEdit} from "react-icons/gr";
import NepaliDate from "nepali-date-converter";
import {canAddStudent} from "../../utils/common";
import StudentProgress from "./StudentProgress";
import {MdDelete} from "react-icons/md";
import CoustomAlertDialog from "../../components/CoustomAlertDialog";
import {onDeleteStudent, onGetMyStudents, onGetStudent} from "../../http/student";
import {School} from "../../interfaes/school";


export default function StudentProfile(){
    const {t} = useTranslation('iep');
    const {state} = useLocation();
    const {isOpen, onOpen, onClose} = useDisclosure();
    const {isOpen:isOpenDelete, onOpen:onOpenDelete, onClose:onCloseDelete} = useDisclosure();
    const[iep,setIep]=useState<IEP[]>([]);
    const[student,setStudent]=useState<Student | undefined>(undefined);
    const [selectedIEP,setSelectedIEP]=useState<IEP | undefined>(undefined)
    const navigate = useNavigate();
    const {id}=useParams();



    useEffect(() => {
        console.log('dasdsadsadsa')
        handleGetIEP();
        if(state){
            setStudent(state);
        }
    }, [state]);


    const handleGetIEP=async () => {

            try {
                const response = await onGetIEP(state?state.studentId:id);
                setIep(response.data);
                if(response.data.length>0){
                    setStudent(response.data[0].student)
                }
            } catch (e) {
                console.log(e)
            }

    }

    const handleClose = () => {
        setSelectedIEP(undefined);
        onClose();
        onCloseDelete();
        setDeleteIndex('');
        setLoading(false)
        handleGetIEP();

    }
    const [loading, setLoading] = useState(false);
    const [deleteIndex, setDeleteIndex] = useState("");
    const toast = useToast();

    const handleDeleteIEP = async () => {
        try {
            setLoading(true)
            await onDeleteIEP(state?state.studentId:id,deleteIndex);
            const arrayOfObjects = iep.filter((obj: IEP) => obj.iepId !== deleteIndex);
            setIep(arrayOfObjects);
            toast({
                title: 'Deleted',
                description: "IEP Deleted Successfully!",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })

        } catch (e) {
            toast({
                title: 'Error',
                // @ts-ignore
                description: e?.response?.data?.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        } finally {
            handleClose();
        }


    }


    if(student) {
        const parent=student.relationships?.filter((r:RelationShip)=>r.relation===Roles.PARENT);

        return (
            <Box>
                <Flex justifyContent="space-between">
                    <Text fontSize="30px" fontWeight={900}>
                        {t('title')}
                    </Text>
                </Flex>
                <Box p={"12px"} background={"#ffffff"} mt={"18px"} borderRadius={'12px'}>
                    <Flex>
                        <Box w={"10%"}>


                                <Image
                                    borderRadius='full'
                                    w={"100%"}
                                    src={student.studentImage}
                                    alt='Dan Abramov'
                                    fallbackSrc='https://via.placeholder.com/150'
                                />
                        </Box>
                        <Box w={"2%"}/>
                        <Box w={"78%"}>
                            <Text fontWeight={'900'} fontSize={'24px'}>
                                {student.fullName}
                            </Text>
                            <Text  fontWeight={'500'} fontSize={'18px'} color={'gray.500'}>
                                { `${t('address')} : ${student.province} | ${student.district} | ${student.localLevel} | ${student.localAddress}`}
                            </Text>
                            {parent && (
                            <Text  fontWeight={'500'} fontSize={'18px'} color={'gray.500'}>
                                { `${t('parentInformation')} : ${parent[0].relatedUser.displayName} | ${t('relation')} : ${parent[0].relation} | ${t('contact')} : ${parent[0].relatedUser.phone}`}
                            </Text>
                                )}
                        </Box>
                    </Flex>
                    <Text fontSize="22px" fontWeight={900} mt={'12px'}>{t('iepProfile')}</Text>
                    {canAddStudent() && (
                    <Button leftIcon={<MdAdd />} colorScheme='pink' variant='solid' mt={'12px'} backgroundColor={'#1b73e8'} borderRadius={'20px'} onClick={onOpen}>
                        {t('addIep')}
                    </Button>)}
                    <Flex mt={'16px'}>

                    <Box w={'50%'} borderRadius={'12px'} background={'gray.100'} p={'12px'} mr={'12px'}>
                    {iep.map((i)=>
                        <Card mt={"12px"} background={i.completed?'green.100':'blue.100'} cursor={'pointer'} >
                            <CardHeader pb={'0px'}>
                                <Flex justifyContent={'space-between'}>
                                    <Heading size='md' pb={'0px'} onClick={()=>navigate('/iep/'+i.iepId,{state:{
                                            student:student,
                                            iep:i
                                        }})}>   {i.year}</Heading>
                                    <Flex alignItems={'center'}>
                                        <Badge variant='solid' colorScheme={i.completed?'green':'blue'} mr={'10px'} onClick={()=>navigate('/iep/'+i.iepId,{state:{
                                                student:student,
                                                iep:i
                                            }})}>
                                            {i.completed?t('completed'):t('inProgress')}
                                        </Badge>
                                        <IconButton
                                            mr={'10px'}
                                            colorScheme='gray'
                                            aria-label='Search database'
                                            icon={ <MdFileDownload size="20px" color={"blue"}/>}
                                            onClick={()=>{
                                               navigate('/student-report/'+student?.studentId+'/'+i?.iepId)
                                            }}
                                        />
                                        <IconButton
                                            colorScheme='gray'
                                            aria-label='Search database'
                                            icon={ <GrEdit size="20px" color={"red"}/>}
                                            onClick={()=>{
                                                setSelectedIEP(i);
                                                onOpen();
                                            }}
                                        />
                                        {canAddStudent() && (

                                        <IconButton
                                        ml={'10px'}
                                            colorScheme='gray'
                                            aria-label='Search database'
                                            icon={ <MdDelete size="20px" color={"red"}/>}
                                            onClick={()=>{
                                                setDeleteIndex(i.iepId || '');
                                                onOpenDelete();
                                            }}
                                        />
                                            )}


                                    </Flex>
                                </Flex>

                            </CardHeader>
                            <CardBody onClick={()=>navigate('/iep/'+i.iepId,{state:{
                                    student:state,
                                    iep:i
                                }})}>
                                <Stack divider={<StackDivider />}>
                                    <Text  fontSize='sm'>
                                    {`${t('roll')} ${i.roll} | ${t('section')} ${i.section} | ${t('level')} ${i.level}`}
                                    </Text>
                                </Stack>
                            </CardBody>

                        </Card>
                    )}
                    </Box>
                        <Box w={'50%'} borderRadius={'12px'} background={'gray.100'} p={'12px'}>
                            <StudentProgress studentID={state?state.studentId:id}/>
                        </Box>

                    </Flex>
                </Box>
                <ManageIEP isOpen={isOpen} onOpen={onOpen} onClose={handleClose} studentId={state?state.studentId:id} selectedIEP={selectedIEP}/>
                <CoustomAlertDialog
                    onClose={handleClose}
                    onOpen={onOpenDelete}
                    isOpen={isOpenDelete}
                    onMainAction={handleDeleteIEP}
                    loading={loading}
                />

            </Box>
        )
    }else{
        return <Box>
            <Stack>
                <Skeleton height='20px' />
                <Skeleton height='20px' />
                <Skeleton height='20px' />
            </Stack>
        </Box>
    }
}
